import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    return (
        <>  
            <PageWrapper headerConfig={headerConfig}>
                <SEO title="Sunrock" />
                <Content>
                    <BackButton />
                    <Container className="">
                        <Row className="">
                            <Col className="col-lg-2"></Col>
                            <Col className="col-lg-6">
                                <PageTitle
                                    title="Sunrock"
                                    align="left"
                                />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="col-lg-2 text-right">
                                <div className="mb-4 border-top pt-7">
                                    {/* COFRA Group */}
                                </div>
                                <div>
                                    {/* Last updated<br />
                                    2 March, 2022 */}
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <StaticImage
                                    src="img_sunrock solars.jpg"
                                    alt="Array of solar panels"
                                    placeholder="blurred"
                                    layout="fixed"
                                    width={480}
                                    style={{maxWidth: '100%'}}
                                    className="mb-10"
                                />
                                <p>
                                    Launched in 2012, Sunrock has established expertise in developing, financing and managing large-scale solar energy projects involving commercial real estate in the Netherlands, where we are a leading solar specialist, and now growing to other European markets. We recognise that the built environment is not only a major energy consumer, it can also be a source of solar energy. Taking advantage of the huge potential offered by underutilised commercial rooftop space, our mission is to help build a more sustainable future and contribute to the transition towards clean energy in Europe.
                                </p>
                                <p>
                                    Sunrock does not simply trade in solar panels; Sunrock contributes its input to finding innovative and tailored solutions for its customers. A distribution centre has different requirements from a port area, after all. We make the sun work for you. From bright idea to maintenance.
                                </p>
                                <p>
                                    In recent years, Sunrock has become the market leader in large-scale solar installations in the Netherlands. Their sustainable energy solutions, from those developed for the Coolblue distribution centres to VidaXL, are continuously being expanded with additional property development, water and carport projects throughout Europe. 
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Content>
            </PageWrapper>
        </>
    )
}

export default BlogArticle;